

$(".js-open-video").modalVideo();
$('.hamburger').click(function () {
    $(this).toggleClass('is-open');
    $('.page-01').toggleClass('is-open')
})

$(document).ready(function () {
    const linkHash = window.location.hash.replace("#", "");
    if (linkHash != '') {
        $.scrollTo('.' + linkHash + '', 800);
    }

})
$(document).on('click', '.js-scroll-to', function () {
    // alert('k')
    var w = $(window).outerWidth();
    if (w < 501){
        $('.hamburger').toggleClass('is-open');
        $('.page-01').toggleClass('is-open')
    }
    const scroll = $(this).attr('data-scroll');
    let speed = parseInt($(this).attr('data-speed'), 10);
    //		const offset = parseInt($(this).attr('data-offset'), 10);
    if (isNaN(speed)) {
        speed = +900;
    }
    $.scrollTo('.' + scroll + '', speed, { offset: 0});
    return false;
})

$('body').scrollspy({
    target: '.fixed-side-navbar',
    offset: 200
  });
//   $('.fixed-side-navbar a').click(function(e){
// e.preventDefault();
//     $.scrollTo($(this).attr('href'), 1800, {offset: 0});
//   }

//   );